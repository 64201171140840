<template>

  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px;">

      <the-modal v-show="deleteConfirmActive" style="position: absolute; width: 100%; z-index: 500;">

        <div @click="closeEverything" style="left: 0px; position: fixed; height: 100%; width: 100%;"></div>

        <div
          style="position: aboslute; max-width: 450px; margin-left: auto; margin-right: auto; z-index: 360; margin-top: 50px;">

          <b-row>
            <b-col class="bg-white text-center" style="padding-bottom: 25px; border-radius: 10px;">
              <h1 style="font-size: 25px; font-weight: 400; margin-top: 20px;">Are you sure?</h1>
              <p style="font-weight: 500; margin-top: 25px;">You are about to delete the event:<br><strong>
                  {{ targetedEvent.name }}</strong></p>
              <b-button @click="deleteEvent(targetedEvent.event_id)" v-show="!processing" type="submit"
                class="btn btn-danger btn-dash-submit">Yes, Delete</b-button>
              <b-button v-show="processing" disabled type="submit" class="btn btn-primary btn-dash-submit">
                <b-spinner class="mr-2" small></b-spinner> Deleting...
              </b-button>
              <b-button @click="closeEverything" class="btn btn-light ml-3">Cancel</b-button>
            </b-col>
          </b-row>
        </div>
      </the-modal>

      <b-row>
        <b-col lg="12" class="mb-4">
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body style="min-height: 150px;">
              <b-icon-plus @click="newEvent()" class="plus-icon"></b-icon-plus>
              <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600;">Upcoming Events
                <!-- <span
                  style="background-color: rgb(23, 92, 142); margin-left: 10px; padding: 5px; border-radius: 20px; color: white; font-weight: 400; height: 25px; min-width: 25px; text-align: center; font-size: 14px;">
                  {{ eventsFutureCount }}</span> -->
              </h1>
              <div class="pt-4">

                <b-alert show v-if="eventsFutureCount == 0" variant="warning">
                  <p style="margin-top: 0px; margin-bottom: 0px; font-size: 1.1rem;">
                    <b-icon icon="exclamation-circle-fill" class="mr-2"></b-icon> No upcoming events found.
                  </p>
                </b-alert>

                <b-row style="margin-bottom: 50px; align-items: flex-start;" v-for="(event, index) in eventsFuture"
                  :key="index">

                  <b-col md="7">
                    <b-img fluid v-if="event.image_url" :src="filePath + event.image_url" class="p-2 mb-2 mr-3"></b-img>
                    <b-img fluid v-else :src="imagePlaceholder" class="p-3 mb-2 mr-3"
                      style="border-radius: 15px; border: 4px dashed #B2B2B2;"></b-img>
                  </b-col>

                  <b-col cols="5">

                    <b-row>
                      <b-col cols="auto">
                        <a @click.prevent="toggleDeleteConfirm(index)" class="small p-1 btn-delete" href="#">
                          <b-icon-trash></b-icon-trash> Delete
                        </a>
                      </b-col>
                      <b-col cols="auto">
                        <router-link class="small p-1 btn-dashboard"
                          :to="{ name: 'Edit Event', params: { eventId: event.event_id }}">
                          <b-icon-pen></b-icon-pen> Edit
                        </router-link>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="mt-2">
                        <b-badge v-show="event.active == false" class="mr-2 mt-1 float-left" variant="warning">Inactive
                        </b-badge>
                        <p style="font-size: 18px; font-weight: 600; margin-bottom: 0px;">{{ event.name }}</p>

                        <p class=" small" style="margin-top: 0px;">{{ event.dateFormatted }} @ {{ event.time }}</p>
                        <p>{{ event.date }}</p>
                        <p class="small">{{ event.description }}</p>
                        <a class="small" target="_BLANK" :href="event.registration_btn_url">{{
                          event.registration_btn_text
                          }}</a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" class="mb-4">
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body style="min-height: 150px;">
              <b-icon-plus @click="newEvent()" class="plus-icon"></b-icon-plus>
              <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600;">Past Events
                <!-- <span
                  style="background-color: rgb(23, 92, 142); margin-left: 10px; padding: 5px; border-radius: 20px; color: white; font-weight: 400; height: 25px; min-width: 25px; text-align: center; font-size: 14px;">
                  {{ eventsFutureCount }}</span> -->
              </h1>
              <div class="pt-4">

                <b-alert show v-if="eventsPastCount == 0" variant="warning">
                  <p style="margin-top: 0px; margin-bottom: 0px; font-size: 1.1rem;">
                    <b-icon icon="exclamation-circle-fill" class="mr-2"></b-icon> No past events found.
                  </p>
                </b-alert>

                <b-row style="margin-bottom: 50px; align-items: flex-start;" v-for="(event, index) in eventsPast"
                  :key="index">

                  <b-col md="7">
                    <b-img fluid v-if="event.image_url" :src="filePath + event.image_url" class="p-2 mb-2 mr-3"></b-img>
                    <b-img fluid v-else :src="imagePlaceholder" class="p-3 mb-2 mr-3"
                      style="border-radius: 15px; border: 4px dashed #B2B2B2;"></b-img>
                  </b-col>

                  <b-col cols="5">

                    <b-row>
                      <b-col cols="auto">
                        <a @click.prevent="toggleDeleteConfirm(index)" class="small p-1 btn-delete" href="#">
                          <b-icon-trash></b-icon-trash> Delete
                        </a>
                      </b-col>
                      <b-col cols="auto">
                        <router-link class="small p-1 btn-dashboard"
                          :to="{ name: 'Edit Event', params: { eventId: event.event_id }}">
                          <b-icon-pen></b-icon-pen> Edit
                        </router-link>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="mt-2">
                        <b-badge v-show="event.active == false" class="mr-2 mt-1 float-left" variant="warning">Inactive
                        </b-badge>
                        <p style="font-size: 18px; font-weight: 600; margin-bottom: 0px;">{{ event.name }}</p>

                        <p class=" small" style="margin-top: 0px;">{{ event.dateFormatted }} @ {{ event.time }}</p>
                        <p>{{ event.date }}</p>
                        <p class="small">{{ event.description }}</p>
                        <a class="small" target="_BLANK" :href="event.registration_btn_url">
                          {{ event.registration_btn_text }}</a>
                      </b-col>
                    </b-row>

                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

    </b-col>
  </b-row>

</template>

<script>
  
  import TheModal from '@/components/TheModal.vue'

  export default {

  name: 'events',

  components: {
    TheModal,
  },

  metaInfo: {
    title: 'Events'
  },

  created () {
    this.getEvents();
  },

  data() {
    return {
      events: {},
      eventsPast: {},
      eventsFuture: {},
      targetedEvent: {},
      processing: false,
      imagePlaceholder: '/event_image_placeholder.jpg',
      filePath: process.env.VUE_APP_FILES_PATH,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      dayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wenesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
    }
  },

  methods: {

    formatDate(date) {
      const rawDate = new Date(date + 'T00:00:00');
      return this.dayNames[rawDate.getDay()] + ', ' + this.monthNames[rawDate.getMonth()] + ' ' + rawDate.getDate();
    },

    getEvents(){
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'events', {       
          // auth: {
          //   username: this.$store.state.auth.apiUsername,
          //   password: this.$store.state.auth.apiPassword,
          // }
        })
        .then(resp => {
          this.events = resp.data;
          this.setFormattedDates();
          this.setEventsPast();
          this.setEventsFuture();
        })
    },

    setFormattedDates() {
      for (var i = 0; i < this.events.length; i++) {
        this.events[i].dateFormatted = this.formatDate(this.events[i].date);
      }
    },

    setEventsPast() {
      this.eventsPast = this.events.filter(e => new Date(e.date) < new Date());
    },

    setEventsFuture() {
      this.eventsFuture = this.events.filter(e => new Date(e.date) >= new Date());
    },

    newEvent() {
      this.$router.push('/events/new');
    },

    toggleDeleteConfirm(index) {
      window.scrollTo(0,0);
      this.updateTargetedEvent(index);
      this.$store.commit("toggleModal");
      this.$store.commit("toggleDeleteConfirm");
    },

    updateTargetedEvent(index) {
      this.targetedEvent = this.events[index];
    },

    deleteEvent(event_id){
      this.processing = true;
      this.$store.commit("generateApiCreds");
      const self = this;

      let url = process.env.VUE_APP_API_PATH + "events/" + event_id;

      this.$axios.delete(url, { 
        auth: {
          // this is to make sure our vue app is authorized
          username: this.$store.state.auth.apiUsername,
          password: this.$store.state.auth.apiPassword,
        },
        data: { 
          // this is to make sure our user is authorized
          user_id: this.$store.state.auth.userId,
          token: this.$store.state.auth.token,
        },
      })
      .then(function() {
        self.getEvents();
        self.processing = false;
        self.$store.commit("closeEverything");
      })
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    }

  },

  computed: {

    eventsFutureCount() {
      return this.eventsFuture.length;
    },

    eventsPastCount() {
      return this.eventsPast.length;
    },

    deleteConfirmActive() {
      return this.$store.state.deleteConfirmActive ? true : false;
    },

  },

};

</script>

<style scoped>

.plus-icon {
  height: 40px;
  width: 40px;
  background-color: rgb(245, 247, 250);
  color: #212529;
  border-radius: 25px;
  float: right;
}

.plus-icon:hover {
  background-color: rgb(23, 92, 142);
  cursor: pointer;
  color: white;
}

.widget-item {
  text-decoration: none;
}

.widget-item:hover {
  background-color: grey;
}

.table > tbody > tr:first-child > td {
    border: none;
}

.btn-dashboard {
  color: #6c757d;
  padding: 5px;
}

.btn-dashboard:hover {
  color: white;
  border-radius: 5px;
  background-color: rgb(23, 92, 142);
  text-decoration: none;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}


</style>